import React, { CSSProperties, useState, useEffect } from "react";
import LavaLamp from "./LavaLamp";
import Header from "./Header";

interface Link {
  id: number;
  url: string;
  label: string;
}

interface Resource {
  id: number;
  title: string;
  description: string;
  links: Link[];
}

const Resources: React.FC = () => {
  const resourcesData: Resource[] = [
    {
      id: 1,
      title: "Internet Computer",
      description: "A collection of links for Internet Computer developers.",
      links: [
        {
          id: 1,
          url: "https://sdk.dfinity.org/docs/index.html",
          label: "Dfinity Developer Center",
        },
        {
          id: 3,
          url: "https://github.com/dfinity/motoko",
          label: "Motoko Programming Language",
        },
        {
          id: 4,
          url: "https://github.com/dfinity/cdk-rs",
          label: "Rust Canister Development Kit",
        },
        {
          id: 5,
          url: "https://github.com/dfinity/agent-js",
          label: "JavaScript Agent Library",
        },

        {
          id: 7,
          url: "https://github.com/dfinity/agent-rs",
          label: "Rust Agent Library",
        },
        {
          id: 8,
          url: "https://medium.com/dfinity",
          label: "Dfinity Blog",
        },
        {
          id: 10,
          url: "https://dashboard.internetcomputer.org/",
          label: "IC Dashboard",
        },
      ],
    },
    // Add more resources here
  ];

  const containerStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "baseline",
    alignItems: "center",
    minHeight: "100vh",
    fontFamily: "'Roboto', sans-serif",
    backgroundColor: "#121212",
    padding: "2rem",
    position: "relative",

    zIndex: 10,
  };

  const titleStyle: CSSProperties = {
    fontSize: "clamp(2.5rem, 4vw, 3rem)",
    textAlign: "left",
    marginBottom: "1rem",
    color: "#b3b3b3",
    alignItems: "left",
    zIndex: 10,
  };

  const listStyle: CSSProperties = {
    listStyleType: "none",
    padding: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    zIndex: 10,
  };

  const innerListStyle: CSSProperties = {
    listStyleType: "none",
    padding: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    width: "100%",
    zIndex: 10,
  };

  const listItemStyle: CSSProperties = {
    backgroundColor: "#1e1e1e",
    borderRadius: "5px",
    marginBottom: "1.5rem",
    padding: "1.5rem",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    width: "100%",
    maxWidth: "800px",
    textAlign: "left",
    background: "rgba(255, 255, 255, 0.1)",
    backdropFilter: "blur(10px)",
    zIndex: 10,
  };

  const linkStyle: CSSProperties = {
    fontSize: "clamp(1.5rem, 3vw, 1.75rem)",
    textDecoration: "none",
    marginBottom: "0.5rem",
    color: "#cff3ff",
    transition: "color 0.3s",
    textAlign: "left",
    zIndex: 10,
  };

  const resourceTitle: CSSProperties = {
    fontSize: "clamp(2.5rem, 3vw, 1.75rem)",
    textDecoration: "none",
    marginBottom: "0.5rem",
    color: "#cff3ff",
    transition: "color 0.3s",
    textAlign: "left",
    zIndex: 10,
  };

  const descriptionStyle: CSSProperties = {
    fontSize: "clamp(1rem, 2vw, 1.25rem)",
    color: "#b3b3b3",
    zIndex: 10,
  };

  const urlStyle: CSSProperties = {
    fontSize: "clamp(1rem, 2vw, 1.25rem)",
    textDecoration: "none",
    marginLeft: "0.5rem",
    color: "#b3b3b3",
    zIndex: 10,
  };

  return (
    <div>
      <Header />
      <div style={containerStyle}>
        <LavaLamp />
        <h2 style={titleStyle}>Resources</h2>
        <ul style={listStyle}>
          {resourcesData.map((resource) => (
            <li key={resource.id} style={listItemStyle}>
              <h3 style={resourceTitle}>{resource.title}</h3>

              <p style={descriptionStyle}>{resource.description}</p>
              <ul style={innerListStyle}>
                {resource.links.map((link) => (
                  <li key={link.id}>
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={linkStyle}
                    >
                      {link.label}
                    </a>
                    <span style={urlStyle}>-</span>
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={urlStyle}
                    >
                      {link.url}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Resources;
