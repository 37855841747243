import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./LiveWritingEditor.css";
import p5 from "p5";

interface LiveWritingEditorProps {
  onContentChange: (content: string) => void;
}

const LiveWritingEditor: React.FC<LiveWritingEditorProps> = ({
  onContentChange,
}) => {
  const [content, setContent] = useState("");
  const [treeSize, setTreeSize] = useState(10);
  const editorRef = useRef<HTMLDivElement | null>(null);
  const treeCanvasRef = useRef<HTMLDivElement | null>(null);
  const p5Ref = useRef<p5 | null>(null);

  const setupP5 = (p: p5) => {
    p.setup = () => {
      p.createCanvas(window.innerWidth, window.innerHeight);
      p.background(255);
    };
  };

  const drawTree = (p: p5, x: number, y: number, size: number) => {
    if (size < 2) {
      return;
    }

    p.line(x, y, x, y - size);
    p.push();
    p.translate(x, y - size);
    p.rotate(p.radians(-25));
    drawTree(p, 0, 0, size * 0.67);
    p.rotate(p.radians(50));
    drawTree(p, 0, 0, size * 0.67);
    p.pop();
  };

  useEffect(() => {
    if (!treeCanvasRef.current) return;

    p5Ref.current = new p5(setupP5, treeCanvasRef.current);
    return () => {
      p5Ref.current?.remove();
    };
  }, []);

  const handleContentChange = (content: string) => {
    setContent(content);
    const newSize = 10 + content.split(" ").length * 2;
    setTreeSize(newSize);

    if (p5Ref.current) {
      p5Ref.current.background(255);
      p5Ref.current.stroke(0);
      drawTree(
        p5Ref.current,
        p5Ref.current.width / 2,
        p5Ref.current.height,
        newSize
      );
    }
  };

  const handleKeyPress = () => {
    const { current: editorElement } = editorRef;
    if (!editorElement) return;

    const animationElement = document.createElement("span");
    animationElement.className = "animation";
    animationElement.style.left = Math.random() * 100 + "%";
    animationElement.style.top = Math.random() * 100 + "%";
    animationElement.style.animationDuration = Math.random() * 1 + 1 + "s"; // Random duration between 1s and 2s
    animationElement.style.animationDelay = Math.random() * 0.5 + "s"; // Random delay between 0s and 0.5s
    editorElement.appendChild(animationElement);

    setTimeout(() => {
      editorElement.removeChild(animationElement);
    }, 2500);
  };

  return (
    <div className="editor-container">
      <div ref={treeCanvasRef} className="tree-canvas"></div>
      <div className="live-writing-editor" ref={editorRef}>
        <ReactQuill
          value={content}
          onChange={handleContentChange}
          onKeyPress={handleKeyPress}
        />
      </div>
    </div>
  );
};

export default LiveWritingEditor;
