import React from "react";
import { useState } from "react";
import Aiplayground from "./aiplayground";

const Secret = () => {
  const [content, setContent] = useState("");

  const handleContentChange = (newContent: string) => {
    setContent(newContent);
  };

  const countWords = (text: string): number => {
    return text
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0).length;
  };

  const wordCount = countWords(content);

  return (
    <div className="App">
      <h1>Live Writing Editor</h1>
      <Aiplayground onContentChange={handleContentChange} />
    </div>
  );
};

export default Secret;
