import React, { useRef, useEffect } from "react";
import p5 from "p5";

interface Particle {
  x: number;
  y: number;
  vx: number;
  vy: number;
  size: number;
  color: p5.Color;
  opacity: number;
  opacityDirection: number;
  glowFactor: number;
}

class FormlessObject {
  pos: p5.Vector;
  vel: p5.Vector;
  size: number;
  angle: number;
  color: p5.Color;
  shapeType: number;

  constructor(p: p5) {
    this.pos = p.createVector(p.random(p.width), p.random(p.height));
    this.vel = p.createVector(p.random(-0.5, 0.5), p.random(-0.5, 0.5));
    this.size = p.random(40, 60);
    this.angle = p.random(0, p.TWO_PI);
    this.color = p.color(
      p.random(50, 200),
      p.random(50, 200),
      p.random(200, 255),
      100
    );
    this.shapeType = p.floor(p.random(0, 3));
  }

  move(p: p5) {
    this.pos.add(this.vel);
    this.angle += p.radians(p.random(-0.5, 0.5));
    if (this.pos.x < 0 || this.pos.x > p.width) {
      this.vel.x = -this.vel.x;
      this.changeColorAndSize(p);
    }
    if (this.pos.y < 0 || this.pos.y > p.height) {
      this.vel.y = -this.vel.y;
      this.changeColorAndSize(p);
    }
  }

  changeColorAndSize(p: p5) {
    this.size = p.random(40, 60);
    this.color = p.color(
      p.random(50, 200),
      p.random(50, 200),
      p.random(200, 255),
      100
    );
    this.shapeType = p.floor(p.random(0, 3));
  }

  display(p: p5) {
    p.push();
    p.translate(this.pos.x, this.pos.y);
    p.rotateX(this.angle);
    p.rotateY(this.angle);
    p.rotateZ(this.angle);
    p.noStroke();
    p.fill(this.color);
    p.ambientLight(200, 200, 200);

    switch (this.shapeType) {
      case 0:
        p.box(this.size);
        break;
      case 1:
        p.sphere(this.size / 2);
        break;
      case 2:
        p.torus(this.size / 2, this.size / 4);
        break;
    }
    p.pop();
  }
}

const NorthernLights: React.FC = () => {
  const canvasRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (canvasRef.current) {
      new p5((p: p5) => {
        let particles: Particle[] = [];
        let formlessObject: FormlessObject;

        function generateParticles() {
          particles = [];
          for (let i = 0; i < 300; i++) {
            particles.push({
              x: p.random(0, p.width),
              y: p.random(0, p.height),
              vx: p.random(-0.1, 0.1),
              vy: p.random(-0.1, 0.1),
              size: p.random(1, 4),
              color: p.color(
                p.random(50, 100),
                p.random(200, 255),
                p.random(100, 255)
              ),
              opacity: p.random(25, 75),
              opacityDirection: p.random([-1, 1]),
              glowFactor: p.random(0, 2 * p.PI),
            });
          }
        }

        p.setup = () => {
          p.createCanvas(p.windowWidth, p.windowHeight, p.WEBGL);
          p.pixelDensity(1);
          generateParticles();
          formlessObject = new FormlessObject(p);
        };

        p.draw = () => {
          p.background(0, 0, 0);
          p.noStroke();

          particles.forEach((particle) => {
            particle.x += particle.vx;
            particle.y += particle.vy;

            // Wrap particles around the screen
            if (particle.x < 0) particle.x = p.width;
            if (particle.x > p.width) particle.x = 0;
            if (particle.y < 0) particle.y = p.height;
            if (particle.y > p.height) particle.y = 0;

            particle.glowFactor += 0.05;
            const glowIntensity = p.map(
              p.sin(particle.glowFactor),
              -1,
              1,
              0.5,
              1.5
            );
            const whiteGlow = p.map(glowIntensity, 0.5, 1.5, 0, 255);

            const newColor = p.color(
              p.red(particle.color) + whiteGlow,
              p.green(particle.color) + whiteGlow,
              p.blue(particle.color) + whiteGlow,
              particle.opacity * glowIntensity
            );

            // Draw shadow
            p.fill(0, 0, 0, particle.opacity * glowIntensity * 0.5);
            p.ellipse(particle.x + 2, particle.y + 2, particle.size);

            // Draw particle
            p.fill(newColor);
            p.ellipse(particle.x, particle.y, particle.size);

            particle.opacity += 0.5 * particle.opacityDirection;
            if (particle.opacity <= 25 || particle.opacity >= 75) {
              particle.opacityDirection *= -1;
            }
          });

          // Move and display the formless object
          formlessObject.move(p);
          formlessObject.display(p);
        };

        p.windowResized = () => {
          p.resizeCanvas(p.windowWidth, p.windowHeight);
          generateParticles();
        };
      }, canvasRef.current);
    }
  }, []);

  return (
    <div
      ref={canvasRef}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 1,
      }}
    ></div>
  );
};

export default NorthernLights;
