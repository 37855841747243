import React, { useState, useEffect, useRef } from "react";

//may need to include in the build process
import { myData } from "../../../src/declarations/myData/index";
import { Post } from "src/declarations/myData/myData.did";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

const defaultImage = require("../assets/default.svg") as string;

const LandingPage = () => {
  const [blogPosts, setBlogPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState(false);

  async function posts() {
    try {
      const posts: Array<Post> = await myData.getUserPosts();
      console.log(posts[1]);
      setBlogPosts(posts);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    posts();
  }, []);

  const refSocial = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (window.location.pathname === "/social") {
      console.log(window.location.pathname);
      refSocial.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (blogPosts.length > 0) {
      setLoading(false);
    }
  }, [blogPosts]);

  function scrollDown() {
    refSocial.current?.scrollIntoView({ behavior: "smooth" });
  }

  interface PortfolioItem {
    title: string;
    subtitle: string;
    details: string;
    languageTags: string;
    imageURL: string;
    link: string;
  }

  const portfolioData: PortfolioItem[] = [
    // {
    //   title: "CogniVault",
    //   subtitle: "The best of Blockchain, the best of AI.",
    //   details:
    //     "This is a proof of concept for what I'd like to become a suite of knowledge management tools for lifelong learning. The MVP is a flash card application that uses AI to help you learn more efficiently, and blockchain to store your data in a decentralized way. This is my vision of true web3, combining AI/Blockchain/XR tech.",
    //   languageTags: "Motoko, Typescript, React, OpenAI",
    //   imageURL:
    //     "https://media3.giphy.com/media/YRcXl6VfNhCorklI0R/giphy.gif?cid=ecf05e47sy57ly38mqw52bn4ohmbhkbmovffu9owbn1p5cmw&rid=giphy.gif&ct=g",
    //   link: "https://schpk-fyaaa-aaaal-abx6q-cai.ic0.app/",
    // },
    // {
    //   title: "Motoko BootCamp Dashboard",
    //   subtitle: "A tool to help manage students and their progress",
    //   details:
    //     "Seb, Motoko BootCamp founder, needed a student management tool. Previously, they manually graded assignments individually, lacking an overview of student progress. I developed an auto-grading tool for 1000+ projects, streamlining their process.",
    //   languageTags: "Motoko, Typescript, React",
    //   imageURL:
    //     "https://media0.giphy.com/media/XbCGmfcW9nvRyl6u6b/giphy.gif?cid=ecf05e47s522pic9gk42jryi93hbf9eh482nciz5jg0c6yv9&rid=giphy.gif&ct=g",
    //   link: "https://motokobootcamp.xyz",
    // },
    // {
    //   title: "Nuance.xyz",
    //   subtitle: "A fully on chain blogging application",
    //   details:
    //     "Implemented and planned the technical side of many of the unique features you see on Nuance today including, decentralized authentication, publications and the article feed.",
    //   languageTags: "Motoko, Typescript, React",
    //   imageURL:
    //     "https://media1.giphy.com/media/Quyiqd41UdRSiaRe55/giphy.gif?cid=ecf05e47ndyx5mxl8nt3nple20ymq60u790z48lbhkrwza4t&rid=giphy.gif&ct=g",
    //   link: "https://exwqn-uaaaa-aaaaf-qaeaa-cai.raw.ic0.app/",
    // },
    // {
    //   title: "ZenAdventure",
    //   subtitle: "A backpacking gear website, to experiment with SEO",
    //   details:
    //     "This is a work in progress, essentially I want to rank for the keyword 'backpacking gear' and see how much traffic I can get. I also used this as an opportunity to learn NEXT.js",
    //   languageTags: "Typescript, React, NEXT.js, Vercel",
    //   imageURL:
    //     "https://media1.giphy.com/media/Quyiqd41UdRSiaRe55/giphy.gif?cid=ecf05e47ndyx5mxl8nt3nple20ymq60u790z48lbhkrwza4t&rid=giphy.gif&ct=g",
    //   link: "https://zenadventureco.com",
    // },
  ];

  return (
    <div className="landing-page">
      <Navbar
        collapseOnSelect
        expand="lg"
        //bg="dark"
        variant="dark"
        className="nav-bar"
      >
        <Container className="nav-bar-container">
          <Navbar.Brand className="logo-block" href="/">
            <img
              src="https://pbs.twimg.com/profile_images/1549178170531774464/o8grCU1a_400x400.jpg"
              width="100"
              height="100"
              className="profile-pic"
              alt="React Bootstrap logo"
            />
            Mitch Kurtzman
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              {/* <Nav.Link href="#features">Features</Nav.Link>
              <Nav.Link href="#pricing">Pricing</Nav.Link> */}
            </Nav>
            <Nav>
              <Nav.Link className="cta" href="/resources">
                Resources
              </Nav.Link>
              <Nav.Link className="cta" onClick={scrollDown}>
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div>
        <div className="hero">
          <div className="hero-text">
            <h1 className="hero-title">Full Stack Blockchain Engineer</h1>
            <p className="hero-subtitle"> Motoko, Typescript, React</p>
            <Button
              variant="outline-light"
              className="cta-button"
              onClick={scrollDown}
            >
              Contact me!{" "}
            </Button>
            {""}
          </div>
          {/* <div>
            <iframe
              src="https://embed.smartcontracts.org/motoko/g/3ZBLnMFBUyASi6DXi54xasFmw9jcRPjKtNtcVgJbrSjMnnco4USQhVbNuH7dj1hdvkuPfiFVGouAb2fGJXrwxg3CHSoPZjTVYi5uHGPohbDm8YweeXTtLD4sKVf9DHiftWaHPHvYexRCRkJMtYLMnS32fVefTk9?lines=10"
              width="100%"
              height="360"
              title="Motoko code snippet"
            />
          </div> */}
        </div>
        {/* <div className="portfolio">
          <h1 className="header">Portfolio</h1>
          <div className="portfolio-items">
            {portfolioData.map((portfolioItem) => (
              <div className="portfolio-item">
                <div className="portfolio-text">
                  <a href={portfolioItem.link}>
                    <img
                      src={portfolioItem.imageURL}
                      alt="header"
                      className="portfolio-image"
                    />
                  </a>
                  <div className="portfolio-title-subtitle">
                    <a href={portfolioItem.link}>
                      <h2 className="portfolio-title">{portfolioItem.title}</h2>
                    </a>
                    <p className="portfolio-subtitle">
                      {portfolioItem.subtitle}
                    </p>
                    <p className="portfolio-details">{portfolioItem.details}</p>
                    <p className="portfolio-language-tags">
                      {portfolioItem.languageTags}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div> */}

        {/* {!loading ? (
          <div className="blog-posts">
            <h1 className="header">Blog Posts</h1>
            {blogPosts.map((post) => (
              <div className="myPosts" key={post.postId}>
                <a href={"http://nuance.xyz" + post.url}>
                  <img
                    src={
                      post.headerImage !== "" ? post.headerImage : defaultImage
                    }
                    alt="header"
                    className="header-image"
                  />
                </a>
                <div className="title-subtitle">
                  <a href={"http://nuance.xyz" + post.url}>
                    <h2 className="post-title">{post.title}</h2>
                  </a>
                  <p className="post-subtitle">{post.subtitle}</p>
                  <p className="post-views">{"views: " + post.views}</p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Spinner animation="border" variant="light" className="spinner" />
        )}
      // </div> */}
      </div>

      {/* <div className="contact-form">
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="form-header">Email address</Form.Label>
            <Form.Control type="email" placeholder="name@example.com" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label className="form-header">Example textarea</Form.Label>
            <Form.Control as="textarea" rows={3} />
          </Form.Group>
        </Form>
      </div> */}
      <div className="footer" ref={refSocial}>
        <div className="social-links">
          <a href="https://twitter.com/EnterTheChain">
            <img
              src={require("../assets/twitter.svg") as string}
              alt="twitter"
              className="social-icon"
            />
          </a>
          <a
            href="
            https://www.linkedin.com/in/mitchell-kurtzman-3aa3651b4/"
          >
            <img
              src={require("../assets/linkedin.svg") as string}
              alt="linkedin"
              className="social-icon"
            />
          </a>
          <a href="https://github.com/MITCHELLKURTZMAN">
            <img
              src={require("../assets/github.svg") as string}
              alt="github"
              className="social-icon"
            />
          </a>
        </div>
        <div className="icp-badge">
          <img src={require("../assets/ICP.svg") as string}></img>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
