import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link, useHref, useNavigate } from "react-router-dom";

const Header = () => {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      //bg="dark"
      variant="dark"
      className="nav-bar"
      style={{ borderBottom: "1px solid #f1f4f5" }}
    >
      <Container className="nav-bar-container">
        <Navbar.Brand className="logo-block" href="/">
          <img
            src="https://pbs.twimg.com/profile_images/1549178170531774464/o8grCU1a_400x400.jpg"
            width="100"
            height="100"
            className="profile-pic"
            alt="React Bootstrap logo"
          />
          Mitch Kurtzman
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {/* <Nav.Link href="#features">Features</Nav.Link>
      <Nav.Link href="#pricing">Pricing</Nav.Link> */}
          </Nav>
          <Nav>
            <Nav.Link className="cta" href="/resources">
              Resources
            </Nav.Link>
            <Nav.Link className="cta" href="/social">
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
